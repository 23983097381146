exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-delete-account-tsx": () => import("./../../../src/pages/delete-account.tsx" /* webpackChunkName: "component---src-pages-delete-account-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-monitoring-tsx": () => import("./../../../src/pages/monitoring.tsx" /* webpackChunkName: "component---src-pages-monitoring-tsx" */),
  "component---src-pages-policy-privacy-policy-tsx": () => import("./../../../src/pages/policy/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-policy-privacy-policy-tsx" */),
  "component---src-pages-policy-returns-and-refunds-tsx": () => import("./../../../src/pages/policy/returns-and-refunds.tsx" /* webpackChunkName: "component---src-pages-policy-returns-and-refunds-tsx" */),
  "component---src-pages-policy-terms-and-conditions-tsx": () => import("./../../../src/pages/policy/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-policy-terms-and-conditions-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/Collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}


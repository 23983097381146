/* eslint-disable no-undef*/
import React, { useState, createContext, useContext, useEffect } from "react";
import Client from "shopify-buy";

const client = Client.buildClient({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  domain: process.env.GATSBY_SHOPIFY_STORE_URL!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN!,
  apiVersion: "",
});

type StoreContext = {
  isOpen: boolean;
  loading: boolean;
  onOpen: () => void;
  onClose: () => void;
  addVariantToCart: (variantId: string, quantity: number) => Promise<void>;
  removeLineItem: (checkoutID: string, lineItemID: string) => Promise<void>;
  updateLineItem: (
    checkoutID: string,
    lineItemID: string,
    quantity: number,
  ) => Promise<void>;
  client: Client;
  checkout?: Client.Checkout;
};
const defaultValues: StoreContext = {
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: async () => {},
  removeLineItem: async () => {},
  updateLineItem: async () => {},
  client,
  checkout: undefined,
};
export const StoreContext = createContext(defaultValues);

export const useStore = () => {
  return useContext(StoreContext);
};

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }: { children: React.ReactNode; }) => {
  const [checkout, setCheckout] = useState<Client.Checkout>();
  const [loading, setLoading] = useState(false);

  const setCheckoutItem = (checkout: Client.Checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id.toString());
    }

    setCheckout(checkout);
  };

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout =
            await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.removeItem(localStorageKey);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart: StoreContext["addVariantToCart"] = async (
    variantId,
    quantity,
  ) => {
    if (!checkout) return;
    setLoading(true);

    const checkoutID = checkout?.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity,
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const removeLineItem: StoreContext["removeLineItem"] = async (
    checkoutID,
    lineItemID,
  ) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID.toString()])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem: StoreContext["updateLineItem"] = async (
    checkoutID,
    lineItemID,
    quantity,
  ) => {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, quantity }];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
